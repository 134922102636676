@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&display=swap);
.search-container {
  background: linear-gradient(to left, rgba(0, 85, 159, 0.6) 100%, transparent),
    url(/static/media/bg.f36346d8.png) 50% 50% no-repeat;
  background-size: cover;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.rw-widget-container {
  background-color: #fff;
  border: none !important;
  border-radius: 4px;
}

.search-container-input-container {
  display: flex;
  padding: 1rem 0;
  align-items: center;
  position: relative;
}

.search-container-input-container svg {
  height: 32px;
  width: 32px;
  margin: 0 1rem;
  position: absolute;
  color: #00559f;
  z-index: 1000;
}

.search-container-input-container input {
  color: #00559f;
  padding: 1rem 1rem 1rem 3rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
}

.search-container-input-container div {
  width: 100%;
}

.search-container-input-container input:focus {
  border-color: #e6edff;
}

.search-container-input-container input::-webkit-input-placeholder {
  font-style: italic;
  color: rgb(52, 55, 58);
}

.search-container-input-container input:-ms-input-placeholder {
  font-style: italic;
  color: rgb(52, 55, 58);
}

.search-container-input-container input::-ms-input-placeholder {
  font-style: italic;
  color: rgb(52, 55, 58);
}

.search-container-input-container input::placeholder {
  font-style: italic;
  color: rgb(52, 55, 58);
}

.search-container-input-container__legend {
  font-size: 0.875rem;
  letter-spacing: 0.015rem;
  color: #f8f9f9;
  font-style: italic;
  margin-left: 2rem;
  display: none;
}

.search_bar-company_counter {
  font-size: 0.875rem;
  letter-spacing: 0.015rem;
  color: #f8f9f9;
  font-style: italic;
}
.search_bar-bottom_flexcontainer_row {
  margin-bottom: 2rem;
  margin-top: 1rem;
  max-width: 640px;
  display: flex;
  flex-wrap: wrap;
}

.search_bar-bottom_flexcontainer_row button {
  width: 150px;
  height: 50px;
  background-color: #fff;
  border: 3px;
  border-style: outset;
  border-radius: 4px;
}

.search_bar-bottom_flexcontainer_row-first_element {
  margin-right: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .search-container-bottom_toolbar_togglegroup {
    margin: 2rem;
  }

  .search-container {
    padding: 0.75rem 4rem 1rem 4rem;
  }

  .search-container input {
    padding: 1.5rem 2rem 1.5rem 5rem;
    max-width: 640px;
  }

  .search-container-input-container div {
    max-width: 640px;
  }

  .search-container svg {
    top: 2.5rem;
    left: 0.5rem;
  }

  .search-container-input-container__legend {
    display: flex;
    justify-content: center;
  }

  .search-container-input-container__legend svg {
    color: #f8f9fa;
    margin-right: 0.5rem;
  }
  .search_bar-bottom_flexcontainer_row {
    margin-bottom: 2rem;
    margin-top: 1rem;
    max-width: 640px;
    display: flex;
  }

  .search_bar-bottom_flexcontainer_row button {
    padding: 0;
    width: 150px;
    height: 50px;
    background-color: #fff;
    border: 3px;
    border-style: outset;
    border-radius: 4px;
  }
  .search_bar-bottom_flexcontainer_row-first_element {
    margin-right: 1rem;
    padding-bottom: 0;
  }
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Open Sans', sans-serif !important;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app_wrapper {
  flex: 1 0 auto;
}

p {
  font-size: 1rem;
}

.show-result-counter {
  font-style: italic;
  font-size: 0.925rem;
  color: black;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.company-info-row__value {
  padding: 0.25rem 0;
  border-bottom: 1px solid #eee;
}

.company-info__close-button {
  display: block;
  background: #00559f;
  color: #fff;
  width: 100%;
  padding: 1.5rem 0.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.email-address {
  text-align: center;
  margin-top: 3rem;
  font-weight: 500;
}

.email-address svg {
  height: 20px;
  margin-right: 0.5rem;
}

.footer {
  text-align: center;
  width: 100%;
  padding: 0.5rem 1rem;
  border-top: 0.125rem solid #eee;
}

.footer p {
  line-height: 1;
  color: #444;
}
.footer a {
  line-height: 1;
  color: #00559f;
}

@media (min-width: 768px) {
  .footer {
    display: flex;
    flex-shrink: 0;
    text-align: left;
    justify-content: safe;
  }

  .footer p:first-child {
    flex: 1 0 60%;
  }
  .footer p:last-child {
    flex: 1 0 40%;
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .main {
    flex-grow: 2;
  }

  .company-info__close-button {
    padding: 0.5rem;
    margin-bottom: 3rem;
    width: 130px;
  }

  .email-address {
    order: 3;
  }
}

@media (min-width: 1440px) {
  .main {
    flex-direction: row;
  }
}

.raksi-alert-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem !important;
  color: inherit;
}

.info-blocks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
    color: #00559f;
    width: 100%;
}

.info-block {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    max-width: 266px;
    min-width: 240px;
    height: 240px;
    text-align: center;
    border-bottom: 6px solid #b3d7f1;
    margin-bottom: 4rem;
}

.info-block svg {
    width: 4em;
    height: 4rem;
    margin-bottom: 2rem;
    color: rgb(179, 215, 241);
}

.info-block h3 {
    font-style: italic;
}

.info-block > p {
    width: 100%;
    line-height: 1.3;
    margin-bottom: 2rem;
}

@media (min-width:1024px) {
    .info-blocks {
        flex-direction: row;
        padding-top: 3.5rem;
    }

    .info-block {
        margin: 0 5% 0 5%;
    }
}
.company-table {
  width: 100%;
  padding: 1rem;
}

.company-table thead {
  border-bottom: 1.5px solid #eee;
}

.company-table th {
  padding: 1rem;
}

.company-table__item {
  cursor: pointer;
}

.company-table__item:hover {
  background: #f5eded;
}

.company-table__item.selected {
  background: #00559f;
  color: white;
}

.company-table__item td {
  padding: 1rem;
}

.company-table__item button {
  display: block;
  background: #00559f;
  color: #fff;
  width: 100%;
  text-align: center;
}

.company-table .no-priority {
  display: none;
}

@media (min-width: 768px) {
  .company-table .no-priority {
    display: table-cell;
  }

  .td_size {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }
}

button:focus {
  background-color: #b4d6f1;
  color: #023c6f;
}

.company-info-container-wrapper {
    display: block;
    width: 100%;
    height: calc(100% - 15rem);
    right: 0;
    flex: 1 1 500px;
    flex-shrink: 0;
    z-index: 9;
    background-color: white;
    overflow-y: auto;
}

.company-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}

.company-info-container_rows {
    padding: 1rem;
}

.company-info-row {
    padding: 0.25rem 0;
}

@media (min-width: 1024px) {
    .company-info-container-wrapper {
        position: fixed;
        height: 100%;
        width: 35%;
        top: 0;
    }

    .company-info-container {
        padding: 3rem;
        border-left: 2px solid #00559f;
    }
  }
.company-table-container {
    padding: 0.5rem;
}

.company-table-container-hidden {
    display: none;
}

@media (min-width: 1024px) { 
  .company-table-container {
        display: block;
        flex-grow: 2;
        margin: 1rem;
        padding: 1rem;    
  }
}
.header {
  background: white;
  border-bottom: 1px solid #eee;
}

.header__language_switch {
  padding: 0.5rem;
  font-style: italic;
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #00559f;
}

.language_switch__text {
  margin-right: 1rem;
}

.header__nav {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  flex-wrap: wrap;
}

.logo__fi {
  cursor: pointer;
  height: 60px;
}

.logo__sv {
  cursor: pointer;
  height: 77px;
}

.header__text {
  display: none;
  position: relative;
  color: #00559f;
  font-style: italic;
  width: 280px;
  line-height: 1.3;
}

.header__text::before {
  position: absolute;
  content: '\201d';
  left: -4rem;
  font-size: 5rem;
  line-height: 1;
  font-family: 'Arial Black', serif;
  color: #b3d7f1;
  font-style: normal;
}

@media (min-width: 768px) {
  .header__text {
    display: block;
  }
}

@media (min-width: 430px) {
  .logo__fi {
    height: 92px;
  }
  .logo__sv {
    height: 110px;
  }
}

