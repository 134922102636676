@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Open Sans', sans-serif !important;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app_wrapper {
  flex: 1 0 auto;
}

p {
  font-size: 1rem;
}

.show-result-counter {
  font-style: italic;
  font-size: 0.925rem;
  color: black;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.company-info-row__value {
  padding: 0.25rem 0;
  border-bottom: 1px solid #eee;
}

.company-info__close-button {
  display: block;
  background: #00559f;
  color: #fff;
  width: 100%;
  padding: 1.5rem 0.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.email-address {
  text-align: center;
  margin-top: 3rem;
  font-weight: 500;
}

.email-address svg {
  height: 20px;
  margin-right: 0.5rem;
}

.footer {
  text-align: center;
  width: 100%;
  padding: 0.5rem 1rem;
  border-top: 0.125rem solid #eee;
}

.footer p {
  line-height: 1;
  color: #444;
}
.footer a {
  line-height: 1;
  color: #00559f;
}

@media (min-width: 768px) {
  .footer {
    display: flex;
    flex-shrink: 0;
    text-align: left;
    justify-content: safe;
  }

  .footer p:first-child {
    flex: 1 0 60%;
  }
  .footer p:last-child {
    flex: 1 0 40%;
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .main {
    flex-grow: 2;
  }

  .company-info__close-button {
    padding: 0.5rem;
    margin-bottom: 3rem;
    width: 130px;
  }

  .email-address {
    order: 3;
  }
}

@media (min-width: 1440px) {
  .main {
    flex-direction: row;
  }
}

.raksi-alert-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem !important;
  color: inherit;
}
