.company-info-container-wrapper {
    display: block;
    width: 100%;
    height: calc(100% - 15rem);
    right: 0;
    flex: 1 1 500px;
    flex-shrink: 0;
    z-index: 9;
    background-color: white;
    overflow-y: auto;
}

.company-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}

.company-info-container_rows {
    padding: 1rem;
}

.company-info-row {
    padding: 0.25rem 0;
}

@media (min-width: 1024px) {
    .company-info-container-wrapper {
        position: fixed;
        height: 100%;
        width: 35%;
        top: 0;
    }

    .company-info-container {
        padding: 3rem;
        border-left: 2px solid #00559f;
    }
  }