.company-table-container {
    padding: 0.5rem;
}

.company-table-container-hidden {
    display: none;
}

@media (min-width: 1024px) { 
  .company-table-container {
        display: block;
        flex-grow: 2;
        margin: 1rem;
        padding: 1rem;    
  }
}