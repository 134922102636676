.company-table {
  width: 100%;
  padding: 1rem;
}

.company-table thead {
  border-bottom: 1.5px solid #eee;
}

.company-table th {
  padding: 1rem;
}

.company-table__item {
  cursor: pointer;
}

.company-table__item:hover {
  background: #f5eded;
}

.company-table__item.selected {
  background: #00559f;
  color: white;
}

.company-table__item td {
  padding: 1rem;
}

.company-table__item button {
  display: block;
  background: #00559f;
  color: #fff;
  width: 100%;
  text-align: center;
}

.company-table .no-priority {
  display: none;
}

@media (min-width: 768px) {
  .company-table .no-priority {
    display: table-cell;
  }

  .td_size {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }
}

button:focus {
  background-color: #b4d6f1;
  color: #023c6f;
}
