.header {
  background: white;
  border-bottom: 1px solid #eee;
}

.header__language_switch {
  padding: 0.5rem;
  font-style: italic;
  color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #00559f;
}

.language_switch__text {
  margin-right: 1rem;
}

.header__nav {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  flex-wrap: wrap;
}

.logo__fi {
  cursor: pointer;
  height: 60px;
}

.logo__sv {
  cursor: pointer;
  height: 77px;
}

.header__text {
  display: none;
  position: relative;
  color: #00559f;
  font-style: italic;
  width: 280px;
  line-height: 1.3;
}

.header__text::before {
  position: absolute;
  content: '\201d';
  left: -4rem;
  font-size: 5rem;
  line-height: 1;
  font-family: 'Arial Black', serif;
  color: #b3d7f1;
  font-style: normal;
}

@media (min-width: 768px) {
  .header__text {
    display: block;
  }
}

@media (min-width: 430px) {
  .logo__fi {
    height: 92px;
  }
  .logo__sv {
    height: 110px;
  }
}
