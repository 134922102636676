.search-container {
  background: linear-gradient(to left, rgba(0, 85, 159, 0.6) 100%, transparent),
    url('../images/bg.png') 50% 50% no-repeat;
  background-size: cover;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.rw-widget-container {
  background-color: #fff;
  border: none !important;
  border-radius: 4px;
}

.search-container-input-container {
  display: flex;
  padding: 1rem 0;
  align-items: center;
  position: relative;
}

.search-container-input-container svg {
  height: 32px;
  width: 32px;
  margin: 0 1rem;
  position: absolute;
  color: #00559f;
  z-index: 1000;
}

.search-container-input-container input {
  color: #00559f;
  padding: 1rem 1rem 1rem 3rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
}

.search-container-input-container div {
  width: 100%;
}

.search-container-input-container input:focus {
  border-color: #e6edff;
}

.search-container-input-container input::placeholder {
  font-style: italic;
  color: rgb(52, 55, 58);
}

.search-container-input-container__legend {
  font-size: 0.875rem;
  letter-spacing: 0.015rem;
  color: #f8f9f9;
  font-style: italic;
  margin-left: 2rem;
  display: none;
}

.search_bar-company_counter {
  font-size: 0.875rem;
  letter-spacing: 0.015rem;
  color: #f8f9f9;
  font-style: italic;
}
.search_bar-bottom_flexcontainer_row {
  margin-bottom: 2rem;
  margin-top: 1rem;
  max-width: 640px;
  display: flex;
  flex-wrap: wrap;
}

.search_bar-bottom_flexcontainer_row button {
  width: 150px;
  height: 50px;
  background-color: #fff;
  border: 3px;
  border-style: outset;
  border-radius: 4px;
}

.search_bar-bottom_flexcontainer_row-first_element {
  margin-right: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .search-container-bottom_toolbar_togglegroup {
    margin: 2rem;
  }

  .search-container {
    padding: 0.75rem 4rem 1rem 4rem;
  }

  .search-container input {
    padding: 1.5rem 2rem 1.5rem 5rem;
    max-width: 640px;
  }

  .search-container-input-container div {
    max-width: 640px;
  }

  .search-container svg {
    top: 2.5rem;
    left: 0.5rem;
  }

  .search-container-input-container__legend {
    display: flex;
    justify-content: center;
  }

  .search-container-input-container__legend svg {
    color: #f8f9fa;
    margin-right: 0.5rem;
  }
  .search_bar-bottom_flexcontainer_row {
    margin-bottom: 2rem;
    margin-top: 1rem;
    max-width: 640px;
    display: flex;
  }

  .search_bar-bottom_flexcontainer_row button {
    padding: 0;
    width: 150px;
    height: 50px;
    background-color: #fff;
    border: 3px;
    border-style: outset;
    border-radius: 4px;
  }
  .search_bar-bottom_flexcontainer_row-first_element {
    margin-right: 1rem;
    padding-bottom: 0;
  }
}
