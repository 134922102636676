.info-blocks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;
    color: #00559f;
    width: 100%;
}

.info-block {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    max-width: 266px;
    min-width: 240px;
    height: 240px;
    text-align: center;
    border-bottom: 6px solid #b3d7f1;
    margin-bottom: 4rem;
}

.info-block svg {
    width: 4em;
    height: 4rem;
    margin-bottom: 2rem;
    color: rgb(179, 215, 241);
}

.info-block h3 {
    font-style: italic;
}

.info-block > p {
    width: 100%;
    line-height: 1.3;
    margin-bottom: 2rem;
}

@media (min-width:1024px) {
    .info-blocks {
        flex-direction: row;
        padding-top: 3.5rem;
    }

    .info-block {
        margin: 0 5% 0 5%;
    }
}